<template>
  <div class="Goods">
    <div class="Goods_box">
      <div class="Goods_nav">
        <!-- <div class="fun-btn-box">
          <img
            src="../../assets/newImages/checkout-icon.png"
            alt=""
            @click="tabChange"
          />
          <div class="btn-text" v-if="changeShow">历史掉落</div>
          <div class="btn-text" v-else>物品列表</div>
        </div> -->
        <div class="nav-title">
          <div v-if="changeShow">物品列表</div>
          <div v-else>历史掉落</div>
        </div>
      </div>
      <GoodsList v-if="changeShow" :DetailList="DetailList" :isCdkeyBox='isCdkeyBox'></GoodsList>
      <GoodsList v-else :DetailList="HistoryList"></GoodsList>
    </div>
  </div>
</template>

<script>
import { BoxUnpackingRecord } from "@/network/api.js";
import GoodsList from "@/components/PubOpen/GoodsList.vue";
export default {
  name: "Goods",
  props: {
    DetailList: {
      //箱内物品列表
      type: Array,
    },
    id: {
      //宝箱id
      type: String & Number,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    isCdkeyBox: {
      type: Boolean,
      default: false,
    },
    isvip: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GoodsList,
  },
  data() {
    return {
      List: this.DetailList,
      changeShow: true,
      Switch: "1", //掉落/物品
      HistoryList: [], //历史掉落列表
      timer: null,
    };
  },

  created() {
    this.GetUnpackingRecord();
    this.$nextTick(function () {
      this.List = this.DetailList;
      console.log(this.List, this.DetailList, "this.List");
    });
  },
  mounted() {
    this.List = this.DetailList;
    console.log(this.List, this.DetailList, "this.List");
  },

  methods: {
    tabChange() {
      this.changeShow = !this.changeShow;
      if (this.changeShow) {
        this.List = this.DetailList;
        console.log(this.List);
      } else {
        this.GetUnpackingRecord();
        // this.List = this.HistoryList;
      }
    },
    // 获得历史掉落记录
    GetUnpackingRecord(funParams = {}) {
      console.log("历史掉落");
      let type_id = 1;
      if (this.isvip) {
        type_id = 12;
      }
      if (this.isCdkeyBox) {
        type_id = 10;
      }
      let params = {
        id: this.id,

        type_id,
      };
      params = { ...params, ...funParams };
      BoxUnpackingRecord(params).then((res) => {
        this.HistoryList = res.data.data;
        this.HistoryList = this.HistoryList.map((item) => ({
          ...item,
          level: item.lv,
        }));
      });
      console.log(this.HistoryList, "历史");
    },
  },
};
</script>

<style lang="scss" scoped>
.Goods {
  // width: 102%;
  box-sizing: border-box;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .Goods_box {
    width: 100%;
    background: #1d1300;
    box-sizing: border-box;
    border: 0.01rem solid #69552d;
    .Goods_nav {
      width: 100%;
      height: 0.38rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
      background: url(../../assets/newImages/list-title-bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 0.13rem;
    
      .fun-btn-box {
        position: absolute;
        left: 0.05rem;
        display: flex;
        align-items: center;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        font-size: 0.1rem;
        color: #ffdda7;
        img {
          width: 0.1rem;
          margin-right: 0.03rem;
        }

        .nav-title {
          
        }
      }
    }
  }
}
</style>
